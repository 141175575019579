import { Action, createReducer, on } from '@ngrx/store';
import * as AgentsActions from './merchants.actions';

export interface State {
    customers: Array<object>
}

export const initialState: State = {
    customers: []
};

const reportsReducer = createReducer(
    initialState,
    on(AgentsActions.getAllCustomers, state => state),
    on(AgentsActions.error, state => state)
);

export function reducer(state: State | undefined, action: Action) {
    return reportsReducer(state, action);
}