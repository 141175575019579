import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

import { CustomSerializer } from './routes/custom-serializer';
import { effects } from './state.effects';
import { reducers } from './state.reducers';

@NgModule({
    imports: [
        StoreModule.forRoot(reducers),
        EffectsModule.forRoot(effects),
        StoreDevtoolsModule.instrument({
            maxAge: 25
        }),
        StoreRouterConnectingModule.forRoot({
            serializer: CustomSerializer
        }),
    ],
    providers: []
})
export class AppStateModule {}