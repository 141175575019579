import { createAction, props } from "@ngrx/store";

export const GET_DAILY_REPORTS = '[Reports] Get Daily Reports';
export const GET_WEEKLY_REPORTS = '[Reports] Get Weekly Reports';
export const GET_MONTHLY_REPORTS = '[Reports] Get Monthly Reports';

export const ERROR = '[Reports] Error';

export const getDailyReports = createAction(GET_DAILY_REPORTS);
export const getWeeklyReports = createAction(GET_WEEKLY_REPORTS);
export const getMonthlyReports = createAction(GET_MONTHLY_REPORTS);

export const error = createAction(ERROR, props<{error: string}>());