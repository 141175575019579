import { Action, createReducer, on } from '@ngrx/store';
import * as AuthActions from './auth.actions';

export interface State {
    uid: string,
    displayName: string,
}

export const initialState: State = {
    uid: '',
    displayName: 'GUEST'
};

const authReducer = createReducer(
    initialState,
    on(AuthActions.getUser, state => state),
    on(AuthActions.register, state => state),
    on(AuthActions.emailLogin, state => state),
    on(AuthActions.connectGoogle, state => state),
    on(AuthActions.connectFacebook, state => state),
    on(AuthActions.resetPassword, state => state),
    on(AuthActions.authenticated, (state, {uid, displayName}) => ({uid: uid, displayName: displayName})),
    on(AuthActions.notAuthenticated, state => ({ ...initialState })),
    on(AuthActions.error, state => state),
);

export function reducer(state: State | undefined, action: Action) {
    return authReducer(state, action);
}