// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BASE_URL: 'http://localhost:4200',
  config: {
    apiKey: "AIzaSyDD1sBLIQnVbIZA3XvOnO8-SaYwaznPR5c",
    authDomain: "admin.zippelin.com",
    databaseURL: "https://zippelin-7b813.firebaseio.com",
    projectId: "zippelin-7b813",
    storageBucket: "zippelin-7b813.appspot.com",
    messagingSenderId: "623677974700",
    appId: "1:623677974700:web:2c97d3a1cdc7b5954b5709",
    measurementId: "G-V23VBYL93Y"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
