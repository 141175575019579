import { createAction, props } from "@ngrx/store";

export const GET_CRITICAL_ORDERS = '[Dashboard - Operation] Get Critical Orders';
export const GET_URGENT_ORDERS = '[Dashboard - Operation] Get Urgent Orders';
export const GET_NORMAL_ORDERS = '[Dashboard - Operation] Get Normal Orders';

export const ERROR = '[Dashboard - Operation] Error';

export const getCriticalOrders = createAction(GET_CRITICAL_ORDERS);
export const getUrgentOrders = createAction(GET_URGENT_ORDERS);
export const getNormalOrders = createAction(GET_NORMAL_ORDERS);

export const error = createAction(ERROR, props<{error: string}>());