import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IDUtilService {
  constructor() {}

  getShortId(initial) {
    return `${initial}${Date.now().toString(36)}`;
  }

  getLongId(initial) {
    return `${initial}${Date.now()}`;
  }

  getTimestampFromShortId(shortId: string) {
    return this.formatDate(new Date(parseInt(shortId.substr(1), 36)));
  }

  getTimestampFromLongId(longId) {
    return new Date(parseInt(longId, 36));
  }

  formatDate(date) {
    const monthNames = [
      'January', 'February', 'March',
      'April', 'May', 'June', 'July',
      'August', 'September', 'October',
      'November', 'December'
    ];

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return `${monthNames[monthIndex]} ${day}, ${year}`;
  }
}
