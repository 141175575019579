import { Injectable } from '@angular/core';
import { ToastrService } from "ngx-toastr";

const defaults = {
    timeOut: 4000,
    closeButton: true,
    tapToDismiss: false,
    enableHtml: true,
    titleClass: 'alert-title',
    positionClass: 'toast-top-right'
};

type TOAST_TYPE = 'default' | 'info' | 'success' | 'warning' | 'danger';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
    constructor(
        private toastr: ToastrService
    ) {}

    showToast(msg: string, type: TOAST_TYPE) {
        this.toastr.show(
            `<span class="alert-icon ni ni-bell-55" data-notify="icon"></span> <div class="alert-text"</div> <span class="alert-title" data-notify="title">Message</span> <span data-notify="message">${msg}</span></div>`,
            '',
            {
                ...defaults,
                toastClass: `ngx-toastr alert alert-dismissible alert-${type} alert-notify`,
            }
        );
    }

    showDefault(msg: string) {
        this.showToast(msg, 'default')
    }

    showInfo(msg: string) {
        this.showToast(msg, 'info')
    }

    showSuccess(msg: string) {
        this.showToast(msg, 'success')
    }

    showWarning(msg: string) {
        this.showToast(msg, 'warning')
    }

    showDanger(msg: string) {
        this.showToast(msg, 'danger')
    }
}
