import { map, catchError, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Observable, of } from 'rxjs';


import * as MerchantsActions from './merchants.actions';

@Injectable()
export class MerchantsEffects {
    getAllAgents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MerchantsActions.GET_ALL_MERCHANTS),
            map(reports => {
                if (reports) {
                    return Observable.create({});
                }
            }),
            catchError(err => of(MerchantsActions.error({ error: err.message })))
        )
    );

    constructor(
        private actions$: Actions
    ) {}
}