import { ActionReducerMap } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';

import * as fromRouter from '@ngrx/router-store';
import * as AuthReducer from './auth/auth.reducer';
import * as DashboardReducers from './dashboard/dashboard.reducers';
import * as ReportsReducer from './reports/reports.reducer';
import * as SettingsReducer from './settings/settings.reducer';
import * as AgentsReducer from './agents/agents.reducer';
import * as CustomersReducer from './customers/customers.reducer';
import * as MerchantsReducer from './merchants/merchants.reducer';

export interface StoreRootState {
  router: fromRouter.RouterReducerState<any>;
  auth: AuthReducer.State,
  dashboard: DashboardReducers.State,
  reports: ReportsReducer.State,
  settings: SettingsReducer.State,
  agents: AgentsReducer.State,
  customers: CustomersReducer.State,
  merchants: MerchantsReducer.State,
}

export const reducers: ActionReducerMap<StoreRootState> = {
  router: routerReducer,
  auth: AuthReducer.reducer,
  dashboard: DashboardReducers.reducer,
  reports: ReportsReducer.reducer,
  settings: SettingsReducer.reducer,
  agents: AgentsReducer.reducer,
  customers: CustomersReducer.reducer,
  merchants: MerchantsReducer.reducer,
};