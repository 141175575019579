import { map, catchError, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Observable, of } from 'rxjs';

import { AuthService } from 'src/app/services/auth.service';

import * as OperationActions from './operation.actions';

@Injectable()
export class OperationEffects {
    getNewOrders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OperationActions.GET_INPROGRESS_ORDERS),
            map(orders => {
                if (orders) {
                    return Observable.create({});
                }
            }),
            catchError(err => of(OperationActions.error({ error: err.message })))
        )
    )

    getInProgressOrders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OperationActions.GET_INPROGRESS_ORDERS),
            map(orders => {
                if (orders) {
                    return Observable.create({});
                }
            }),
            catchError(err => of(OperationActions.error({ error: err.message })))
        )
    )

    getFinishedOrders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OperationActions.GET_FINISHED_ORDERS),
            map(orders => {
                if (orders) {
                    return Observable.create({});
                }
            }),
            catchError(err => of(OperationActions.error({ error: err.message })))
        )
    )

    constructor(
        private actions$: Actions,
        private authService: AuthService
    ) {}
}