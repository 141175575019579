import { Action, createReducer, on } from '@ngrx/store';
import * as ReportsActions from './reports.actions';

export interface State {
    daily: Array<object>,
    weekly: Array<object>,
    monthly: Array<object>
}

export const initialState: State = {
    daily: [],
    weekly: [],
    monthly: []
};

const reportsReducer = createReducer(
    initialState,
    on(ReportsActions.getDailyReports, state => state),
    on(ReportsActions.getWeeklyReports, state => state),
    on(ReportsActions.getMonthlyReports, state => state),
    on(ReportsActions.error, state => state)
);

export function reducer(state: State | undefined, action: Action) {
    return reportsReducer(state, action);
}