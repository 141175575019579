import { NotificationService } from './notification.service';
import { Router } from '@angular/router';
import { Injectable, ApplicationRef } from '@angular/core';
import * as firebase from 'firebase/app';
import { auth } from 'firebase/app';
import { user } from 'rxfire/auth';
import { tap, switchMap } from 'rxjs/operators';
import { docData } from 'rxfire/firestore';
import { of, Observable } from 'rxjs';
// import { NotificationService } from '../notification/notification.service';
// import { onLogout, onLogin, onError } from '../notification/notifications';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authClient = firebase.auth();
  analytics = firebase.analytics();

  user$: Observable<any>;
  userDoc$: Observable<any>;

  userProducts$: Observable<any>;

  user;
  userDoc;

  //save admin to firestore reference
  admin_ref = firebase.firestore().collection('admins');
  agent_ref = firebase.firestore().collection('agents');

  constructor(
    private app: ApplicationRef,
    private router: Router,
    private notif: NotificationService
  ) {
    // Why service subsciptions? Maintain state between route changes with change detection.
    this.user$ = user(this.authClient).pipe(tap(u => {
      if (u) {
        this.user = u;
        this.analytics.setUserId(u.uid);
      } else {
        this.user = null;
      }
      // this.app.tick();
    }));

    this.userDoc$ = this.getUserDoc$('users').pipe(tap(u => {
      if (u) {
        this.userDoc = u;
        this.analytics.setUserProperties({ pro_status: u.pro_status });
      }
      // this.app.tick();
    }));
  }

  getUserDoc$(col) {
    return user(this.authClient).pipe(
      switchMap(u => {
        return u ? docData(firebase.firestore().doc(`${col}/${(u as any).uid}`)) : of(null);
      })
    );
  }

  signOut() {
    this.authClient.signOut();
    this.router.navigate(['auth/login']);
    this.notif.showSuccess('Signout successfuly');
    this.analytics.logEvent('logout', {});
  }

  async googleConnect() {
    const credential = this.authClient.signInWithPopup(new auth.GoogleAuthProvider());
    return this.loginHandler(credential);
  }

  async facebookConnect() {
    const credential = this.authClient.signInWithPopup(new auth.FacebookAuthProvider());
    return this.loginHandler(credential);
  }

  async appleLogin() {
    const provider = new firebase.auth.OAuthProvider('apple.com');
    const credential = this.authClient.signInWithPopup(provider);
    return this.loginHandler(credential);
  }

  get userId() {
    return this.user ? this.user.uid : null;
  }

  async emailSignup(email: string, password: string) {
    const credential = this.authClient.createUserWithEmailAndPassword(email, password).then(
      res => {
        this.admin_ref.doc(res.user.uid).set({user: res.user.email, role: 'admin', org: 'zippelin'});
      }
    )
    return this.loginHandler(credential);
  }

  async emailSignupAgent(data) {
    const credential = this.authClient.createUserWithEmailAndPassword(data.email, data.password).then(
      res => {
        const AgentData = {
          id: res.user.uid,
          email: data.email,
          user: `${data.fname} ${data.lname}`,
          role: 'rider',
          status: 'unverified',
          transport: 'motor',
          device: 'android'
        };
        this.agent_ref.doc(res.user.uid).set(AgentData);
      }
    )
    // return this.loginHandler(credential);
  }

  async emailLogin(email: string, password: string) {
    const credential = this.authClient.signInWithEmailAndPassword(email, password);
    return this.loginHandler(credential);
  }

  async resetPassword(email: string) {
    return this.authClient.sendPasswordResetEmail(email);
  }

  async loginHandler(promise) {
    let res, serverError;
    try {
      res = await promise;
      this.notif.showDanger('Login Success');
      this.analytics.logEvent('login', {});
    } catch (err) {
      serverError = err.message;
      this.notif.showDanger(err.message);
    }
    return { res, serverError };
  }
}
