export const UNASSIGNED = `orgs/zip/tasks/unassigned/`;
export const ASSIGNED = `orgs/zip/tasks/assigned/`;
export const COMPLETED = `orgs/zip/tasks/completed/`;
export const AGENTS = 'orgs/zip/agents/';
export function SET_TASK_KEY(key: string) {
	TASK_CONSTANT = {
		assignedWithKey: `${ASSIGNED}${key}`,
		unAssignedWithKey: `${UNASSIGNED}${key}`,
		deliveryUnassigned: `${UNASSIGNED}${key}/delivery`,
		pickupUnassigned: `${UNASSIGNED}${key}/pickup`,
		deliveryAssigned: `${ASSIGNED}${key}/delivery`,
		pickupAssigned: `${ASSIGNED}${key}/pickup`
	}
	MERCHANT_CONSTANT = {
		inprogressPickup: `mer-tmf/inprogress/${key}/pickup`,
		inprogressDelivery: `mer-tmf/inprogress/${key}/delivery`
	}
};
export function SET_AGENT_KEY(key: string) {
	AGENT_CONSTANT = {
		agent: `${AGENTS}${key}`,
		agentTask: `${AGENTS}${key}/task`
	}
};
export let TASK_CONSTANT;
export let AGENT_CONSTANT;
export let MERCHANT_CONSTANT;