import { Component, OnInit, ElementRef } from "@angular/core";
import { ROUTES } from "../sidebar/sidebar.component";
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import {
  Location,
  LocationStrategy,
  PathLocationStrategy
} from "@angular/common";
import { AuthService } from 'src/app/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as firebase from 'firebase/app';
import { IDUtilService } from 'src/app/services/id.util.service';
import { Task } from '../../models/task.model';
import { Delivery } from '../../models/task.model';
import { Pickup } from '../../models/task.model';
import { TaskCustomer } from '../../models/customer.model';
import { BasicUser } from '../../models/user.model';
import { FirebaseService } from 'src/app/services/firebase.service';
import { DatePipe } from '@angular/common'
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit {
  db = firebase.database();
  public listTitles: any[];
  public location: Location;
  sidenavOpen: boolean = true;
  closeResult: string;
  taskForm: FormGroup;
  validationMessages = {};
  texts: any;
  focus = false;

  constructor(
    location: Location,
    private element: ElementRef,
    private router: Router,
    public auth: AuthService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private idUtil: IDUtilService,
    private fire: FirebaseService,
    public datepipe: DatePipe,
    public translate: TranslateService,
  ) {
    this.location = location;
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator

      }
      if (event instanceof NavigationEnd) {
        // Hide loading indicator

        if (window.innerWidth < 1200) {
          document.body.classList.remove("g-sidenav-pinned");
          document.body.classList.add("g-sidenav-hidden");
          this.sidenavOpen = false;
        }
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }
    });

    translate.get('NAV').subscribe((texts: any) => {
      this.texts = texts;
      this.initializeValidationMessages(this.texts);
    });
  }

  ngOnInit() {
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    this.taskForm = this.fb.group({
      pickup: this.fb.group({
        puMobile: ['', Validators.required],
        puEmail: ['', [Validators.required, Validators.email]],
        puName: ['', Validators.required],
        puAddress: ['', Validators.required],
        puNotes: ['', Validators.required],
        puDateBefore: ['', Validators.required],
      }),
      delivery: this.fb.group({
        dvMobile: ['', Validators.required],
        dvEmail: ['', [Validators.required, Validators.email]],
        dvName: ['', Validators.required],
        dvAddress: ['', Validators.required],
        dvNotes: ['', Validators.required],
        dvDateBefore: ['', Validators.required],
      }),
    });
  }

  initializeValidationMessages(texts: any) {
    const required = texts.shared.required;
    const form = texts.form;
    this.validationMessages = {
      puAddress: [
        { type: 'required', message: `${form.puAddress.field} ${required}` }
      ]
    };
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return "Dashboard";
  }

  openSearch() {
    document.body.classList.add("g-navbar-search-showing");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-showing");
      document.body.classList.add("g-navbar-search-show");
    }, 150);
    setTimeout(function () {
      document.body.classList.add("g-navbar-search-shown");
    }, 300);
  }

  closeSearch() {
    document.body.classList.remove("g-navbar-search-shown");
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-show");
      document.body.classList.add("g-navbar-search-hiding");
    }, 150);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hiding");
      document.body.classList.add("g-navbar-search-hidden");
    }, 300);
    setTimeout(function () {
      document.body.classList.remove("g-navbar-search-hidden");
    }, 500);
  }

  openSidebar() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }

  toggleSidenav() {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.sidenavOpen = false;
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
      this.sidenavOpen = true;
    }
  }

  createTaskModal(content, type, modalDimension) {
    this.modalService.open(content, { size: 'lg' });
  }

  createTask() {
    let puUser: BasicUser = {
      uid: this.idUtil.getShortId("cu"),
      name: this.taskForm.value.pickup.puName,
      email: this.taskForm.value.pickup.puEmail,
      mobile: this.taskForm.value.pickup.puMobile
    }
    let dvUser: BasicUser = {
      uid: this.idUtil.getShortId("cu"),
      name: this.taskForm.value.delivery.dvName,
      email: this.taskForm.value.delivery.dvEmail,
      mobile: this.taskForm.value.delivery.dvMobile
    }
    let pickup: Pickup = {
      id: this.idUtil.getShortId("pu"),
      status: "unassigned",
      customer: puUser,
      dateTime: this.datepipe.transform(this.taskForm.value.pickup.puDateBefore, 'MM-dd-yyyy, h:mm a'),
      notes: this.taskForm.value.pickup.puNotes,
      address: {
        lat: '40.778721',
        lng: '-73.968188',
        location: this.taskForm.value.pickup.puAddress
      }
    }
    let delivery: Delivery = {
      id: this.idUtil.getShortId("dv"),
      status: "unassigned",
      customer: dvUser,
      dateTime: this.datepipe.transform(this.taskForm.value.delivery.dvDateBefore, 'MM/dd/yyyy, h:mm a'),
      notes: this.taskForm.value.delivery.dvNotes,
      address: {
        lat: '40.778721',
        lng: '-73.968188',
        location: this.taskForm.value.delivery.dvAddress
      }
    }
    let taskForm: Task;
    if (this.taskForm.value.delivery.dvAddress == "" && this.taskForm.value.pickup.puAddress != "") {
      taskForm = {
        pickup
      }
    } else if (this.taskForm.value.delivery.dvAddress != "" && this.taskForm.value.pickup.puAddress == "") {
      taskForm = {
        delivery
      }
    } else if (this.taskForm.value.delivery.dvAddress != "" && this.taskForm.value.pickup.puAddress != "") {
      taskForm = {
        delivery,
        pickup
      }
    }
    this.fire.setDataWithId(`orgs/zip/tasks/unassigned`, 'zt', taskForm).then(() => {
      this.modalService.dismissAll();
    });
  }
}
