import { map, catchError, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Observable, of } from 'rxjs';

import { AuthService } from 'src/app/services/auth.service';

import * as ManagementActions from './management.actions';

@Injectable()
export class ManagementEffects {
    getCriticalOrders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManagementActions.GET_CRITICAL_ORDERS),
            map(orders => {
                if (orders) {
                    return Observable.create({});
                }
            }),
            catchError(err => of(ManagementActions.error({ error: err.message })))
        )
    );

    getUrgentOrders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManagementActions.GET_URGENT_ORDERS),
            map(orders => {
                if (orders) {
                    return Observable.create({});
                }
            }),
            catchError(err => of(ManagementActions.error({ error: err.message })))
        )
    );

    getNormalOrders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ManagementActions.GET_NORMAL_ORDERS),
            map(orders => {
                if (orders) {
                    return Observable.create({});
                }
            }),
            catchError(err => of(ManagementActions.error({ error: err.message })))
        )
    );

    constructor(
        private actions$: Actions,
        private authService: AuthService
    ) {}
}