import { createAction, props } from "@ngrx/store";

export const GET_NEW_ORDERS = '[Dashboard - Operation] Get New Orders';
export const GET_INPROGRESS_ORDERS = '[Dashboard - Operation] Get In Progress Orders';
export const GET_FINISHED_ORDERS = '[Dashboard - Operation] Get Finished Orders';

export const ERROR = '[Dashboard - Operation] Error';

export const getNewOrders = createAction(GET_NEW_ORDERS);
export const getInProgressOrders = createAction(GET_INPROGRESS_ORDERS);
export const getFinishedOrders = createAction(GET_FINISHED_ORDERS);

export const error = createAction(ERROR, props<{error: string}>());