import { map, catchError, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Observable, of } from 'rxjs';

import { AuthService } from 'src/app/services/auth.service';

import * as SettingsActions from './settings.actions';

@Injectable()
export class SettingsEffects {
    getUserSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.GET_USER_SETTINGS),
            map(orders => {
                if (orders) {
                    return Observable.create({});
                }
            }),
            catchError(err => of(SettingsActions.error({ error: err.message })))
        )
    );

    getIntegrationSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.GET_INTEGRATION_SETTINGS),
            map(orders => {
                if (orders) {
                    return Observable.create({});
                }
            }),
            catchError(err => of(SettingsActions.error({ error: err.message })))
        )
    );

    getFeatureSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SettingsActions.GET_FEATURE_SETTINGS),
            map(orders => {
                if (orders) {
                    return Observable.create({});
                }
            }),
            catchError(err => of(SettingsActions.error({ error: err.message })))
        )
    );

    constructor(
        private actions$: Actions,
        private authService: AuthService
    ) {}
}