export const environment = {
  production: true,
  // BASE_URL: 'https://admin.zippelin.com/',
  BASE_URL: '/',
  config: {
    apiKey: "AIzaSyDD1sBLIQnVbIZA3XvOnO8-SaYwaznPR5c",
    authDomain: "admin.zippelin.com",
    databaseURL: "https://zippelin-7b813.firebaseio.com",
    projectId: "zippelin-7b813",
    storageBucket: "zippelin-7b813.appspot.com",
    messagingSenderId: "623677974700",
    appId: "1:623677974700:web:2c97d3a1cdc7b5954b5709",
    measurementId: "G-V23VBYL93Y"
  }
};
