import { createAction, props } from "@ngrx/store";

export const GET_USER_SETTINGS = '[Settings] Get User';
export const GET_INTEGRATION_SETTINGS = '[Settings] Get Integration';
export const GET_FEATURE_SETTINGS = '[Settings] Get Feature';

export const ERROR = '[Settings] Error';

export const getUserSettings = createAction(GET_USER_SETTINGS);
export const getIntegrationSettings = createAction(GET_INTEGRATION_SETTINGS);
export const getFeatureSettings = createAction(GET_FEATURE_SETTINGS);

export const error = createAction(ERROR, props<{error: string}>());