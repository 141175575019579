import { MerchantsEffects } from './merchants/merchants.effects';
import { CustomersEffects } from './customers/customers.effects';
import { AgentsEffects } from './agents/agents.effects';
import { AuthEffects } from './auth/auth.effects';
import { DashboardEffects } from './dashboard/dashboard.effects'
import { ReportsEffects } from './reports/reports.effects';
import { SettingsEffects } from './settings/settings.effects';

export const effects = [
    AuthEffects,
    ...DashboardEffects,
    ReportsEffects,
    SettingsEffects,
    AgentsEffects,
    CustomersEffects,
    MerchantsEffects
];