import { Action, createReducer, on } from '@ngrx/store';
import * as SettingsActions from './settings.actions';

export interface State {
    user: Array<object>,
    integration: Array<object>,
    feature: Array<object>
}

export const initialState: State = {
    user: [],
    integration: [],
    feature: []
};

const settingsReducer = createReducer(
    initialState,
    on(SettingsActions.getUserSettings, state => state),
    on(SettingsActions.getIntegrationSettings, state => state),
    on(SettingsActions.getFeatureSettings, state => state),
    on(SettingsActions.error, state => state)
);

export function reducer(state: State | undefined, action: Action) {
    return settingsReducer(state, action);
}