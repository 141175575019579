import { Action, createReducer, on } from '@ngrx/store';
import * as OperationActions from './operation.actions';

export interface State {
    new: Array<object>,
    inprogress: Array<object>,
    finished: Array<object>
}

export const initialState: State = {
    new: [],
    inprogress: [],
    finished: []
};

const operationReducer = createReducer(
    initialState,
    on(OperationActions.getNewOrders, state => state),
    on(OperationActions.getInProgressOrders, state => state),
    on(OperationActions.getFinishedOrders, state => state),
    on(OperationActions.error, state => state)
);

export function reducer(state: State | undefined, action: Action) {
    return operationReducer(state, action);
}