import { Action, createReducer, on } from '@ngrx/store';
import * as ManagementActions from './management.actions';

export interface State {
    critical: Array<object>,
    urgent: Array<object>,
    normal: Array<object>
}

export const initialState: State = {
    critical: [],
    urgent: [],
    normal: []
};

const operationReducer = createReducer(
    initialState,
    on(ManagementActions.getCriticalOrders, state => state),
    on(ManagementActions.getUrgentOrders, state => state),
    on(ManagementActions.getNormalOrders, state => state),
    on(ManagementActions.error, state => state)
);

export function reducer(state: State | undefined, action: Action) {
    return operationReducer(state, action);
}