import { map, catchError, tap, mergeMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of, from } from 'rxjs';

import { AuthService } from 'src/app/services/auth.service';

import * as authActions from './auth.actions';

@Injectable()
export class AuthEffects {
    getUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.GET_USER),
            mergeMap(() => this.authService.user$.pipe(
                map(auth => {
                    if (auth) {
                        return authActions.authenticated({uid: auth.uid, displayName: auth.displayName});
                    } else {
                        return authActions.notAuthenticated();
                    }
                }),
                catchError(err => of(authActions.error({ error: err.message })))
            ))
        )
    );

    emailLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.EMAIL_LOGIN),
            mergeMap((action:any) => from(this.authService.emailLogin(action.email, action.password)).pipe(
                map(() => authActions.getUser()),
                catchError(err => of(authActions.error({ error: err.message})))
            ))
        )
    );

    register$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.REGISTER),
            mergeMap((action:any) => from(this.authService.emailSignup(action.email, action.password)).pipe(
                map(() => authActions.getUser()),
                catchError(err => of(authActions.error({ error: err.message})))
            ))
        )
    );

    googleConnect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.CONNECT_GOOGLE),
            mergeMap(() => from(this.authService.googleConnect()).pipe(
                map(() => authActions.getUser()),
                catchError(err => of(authActions.error({ error: err.message })))
            ))
        )
    );


    facebookConnect$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.CONNECT_FACEBOOK),
            mergeMap(() => from(this.authService.facebookConnect()).pipe(
                map(() => authActions.getUser()),
                catchError(err => of(authActions.error({ error: err.message })))
            ))
        )
    );

    reset$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.RESET_PASSWORD),
            mergeMap((action:any) => from(this.authService.resetPassword(action.email)).pipe(
                map(() => authActions.getUser()),
                catchError(err => of(authActions.error({ error: err.message})))
            ))
        )
    );

    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.LOGOUT),
            tap(() => this.authService.signOut()),
            catchError(err => of(authActions.error({ error: err.message})))
        ), { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private authService: AuthService
    ) {}
}