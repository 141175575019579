import { Action } from '@ngrx/store';
import { createReducer } from '@ngrx/store';
import * as ManagementReducer from './management/management.reducer';
import * as OperationReducer from './operation/operation.reducer';

export interface State {
    management: ManagementReducer.State,
    operation: OperationReducer.State
};

export const initialState: State = {
    management: ManagementReducer.initialState,
    operation: OperationReducer.initialState
};

const operationReducer = createReducer(
    initialState,
);

export function reducer(state: State | undefined, action: Action) {
    return operationReducer(state, action);
}