import { Component, ApplicationRef, OnInit } from "@angular/core";
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { AuthService } from './services/auth.service';
import { TranslateService } from '@ngx-translate/core';

import { Store } from '@ngrx/store';
import { getUser } from './state/auth/auth.actions';
import { State } from './state/auth/auth.reducer';

interface AppState {
  user: State
}
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private app: ApplicationRef,
    public auth: AuthService,
    private translate: TranslateService,
    private store: Store<AppState>
  ) {
    translate.setDefaultLang('en');
    translate.use('en');

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        window.scrollTo(0, 0);
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }
    });
    this.initializeApp();
  }

  ngOnInit() {
    this.store.dispatch(getUser());
  }

  initializeApp() {
    this.auth.user$.subscribe(
      data => {
        if (this.auth.user) {
          this.router.navigate(['dashboard']);
        } else {
          this.router.navigate(['auth/login']);
        }
        this.app.tick();
      }
    );
  }
}
