import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import { IDUtilService } from 'src/app/services/id.util.service';
import { UNASSIGNED, ASSIGNED, COMPLETED, AGENTS } from '../core/constants/rtdb.constants';
import { list } from 'rxfire/database';
import { map, switchMap } from 'rxjs/operators';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { Query } from '@angular/compiler/src/core';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  db = firebase.database();
  //reference
  refUnassigned = this.db.ref(UNASSIGNED);
  refAssigned = this.db.ref(ASSIGNED);
  refCompleted = this.db.ref(COMPLETED);
  refAgents = this.db.ref(AGENTS);

  constructor(
    private idUtil: IDUtilService
  ) {
  }

  // RTDB
  getAgents(f) {
    const filter = new Subject<string>();
    const datas = new Subject<any[]>();
    filter.subscribe({
      next: (et) => {
        let query = this.refAgents.orderByChild("status").equalTo(`${et}`);
        query.on('value', function (agent) {
          if (agent.val()) {
            datas.next(Object.values(agent.val()))
          }
        })
      }
    });
    filter.next(f);
    return datas;
  }

  getListOfData(n) {
    const nodes = new Subject<any>();
    const datas = new Subject<any[]>();
    nodes.subscribe({
      next: (node) => {
        list(node).pipe(
          map(changes => changes.map(c => {
            return { _key: c.snapshot.key, ...c.snapshot.val() }
          }))
        ).subscribe(
          data => {
            datas.next(data);
          }
        );
      }
    });
    nodes.next(n);
    return datas;
  }

  setDataWithId(loc: string, initial: string, object: object) {
    return this.db.ref(`${loc}/` + this.idUtil.getShortId(initial)).set(object);
  }

  updateData(loc: string, object: object) {
    return this.db.ref(loc).update(object);
  }

  removeData(loc: string) {
    this.db.ref(loc).remove();
  }

  static removeData(loc: string) {
    const db = firebase.database();
    db.ref(loc).remove();
  }

  static updateData(loc: string, object: {}) {
    const db = firebase.database();
    return db.ref(loc).update(object);
  }
}
